import * as React from "react"
import * as styles from "../components/Weetjesgenerator2.module.css"
import { useState } from "react";


const Weetjesgenerator = () => {

    const [flipped, setFlipped] = useState(false);
    const [feitje, setFeitje] = useState("random-silke-weetjes-generator");
    const [color, setColor] = useState("#F5F5F5");
    const [border, setBorder] = useState('none');
    const [feitjeNumber, setFeitjeNumber] = useState(0);
    const feitjesArray = [`Alle mogelijke combinaties met Silke Janssens waren bezet en zo is per abuis perabuis.be ontstaan`, "Er is niets dat me meer troost geeft dan wortelpuree", `On a mission om Antwerpse appelbollen en verloren maandag wereldberoemd te maken`, `Viool spelen, tekenacademie en voetbal waren mijn hobby's maar ooit wil ik nog leren ijsschaatsen`, "Chopin, onze kat, is mijn rosse bondgenoot", "In het middelbaar was ik hoofdredacteur van de schoolkrant, zo is mijn hele grafische avontuur ooit begonnen", "Met mijn eerste loonbrief wil ik graag een Creuset pot en een Odette Lunette bril kopen.", "Ik ga graag naar pretparken, zeker op 1 september.",  "Dat waren de weetjes, contacteer me voor meer :) "];
    const colorArray = ["#F57B11", "#D3B7FE", "#CDD6B7", "#FFE017", "#0163FF"];

 function randomNumberInRange(min, max) {
    // 👇️ get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

    const handleClick = () => {
        setFlipped(!flipped);
        setBorder('none');
        setTimeout(() => {
            setColor(colorArray[randomNumberInRange(0,4)]);
          }, 300);
        setTimeout(() => {
          setFeitje('');
            setFeitje(feitjesArray[feitjeNumber]);
            setFeitjeNumber(feitjeNumber+1);
            if(feitjeNumber >= 8){
              setFeitjeNumber(0);
            }
          }, 200);
    }
    

    return (
         <button onClick={() => handleClick()}  className={` ${styles.circle} ${flipped ? styles.circle : styles.flipped}`} style={{backgroundColor: `${color}`, border: `${border}`}}>
            <p className={styles.nieuw}>klik hier</p>
            <p className={styles.feitje}>{feitje}</p>
        </button>
    )
};

export default Weetjesgenerator;
