import * as React from "react"


import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/pages/about.module.css"
import Weetjesgenerator from "../components/Weetjesgenerator2"
import { useState } from "react";
import { StaticImage } from "gatsby-plugin-image"
import { gsap } from "gsap";
import {  useEffect } from "react";



const About = () => {
  const [desktop, setDesktop] = useState(false);

   const updateMedia = () => {
      if(window.innerWidth > 960){
        setDesktop(true);
      }};

   useEffect(() => {
    // 👇️ only runs once
        updateMedia();
      }, []);

    const [firstClick, setFirstClick] = useState(true);  
        const [opacity, setOpacity] = useState(0);  

  const activateFall = () => {
    if(firstClick === true) {
        let timeline = gsap.timeline();
        timeline.to("#weetjesgenerator", {y:320, duration:1.5, ease: "bounce.out"});  
          setOpacity(1);
          setFirstClick(false);
    }
  }

  
  return (
  <Layout>
    <div className={styles.grid}>
      <div className={styles.about}>
          <div>
            <h1 className={styles.aboutTitle}>Hallo, Ik ben Silke</h1>
            <p className={styles.aboutTekst}>In juni studeer ik af in Devine, digital design & development. Eerder behaalde ik een diploma grafische en digitale media waar ik meer focuste op de technische, printmedia kant van de grafische industrie.
            </p>
            <p className={styles.aboutTekst}>Ik hou van de uitdaging die de balans tussen een goede user-experience, mooi design en solide code biedt. <span className={styles.boldtext}>Daarom kijk ik uit naar een uitdaging waarin ik mee mag bouwen een wijze digitale producten.</span> Concreet zoek ik een job in de richting van UX/UI design maar sta ik open om ook op development en/of strategisch vlak mee aan de slag te gaan.
            </p>
            <p className={styles.aboutTekst}>
           Je kan me herkennen aan mijn rosse haren en aanstekelijk enthousiasme. Ex-collega's omschrijven me als heerlijk eerlijk. In mijn vrije tijd kan je me vinden op restaurant of zelf achter de kookpotten.
            </p>
          </div>
          <div className={styles.contact}>
            <p className={styles.contactDescription}>Zin in een <span className={styles.lineThrough}>koffie</span> warme chocomelk, een babbel of heb je een vraag? Contacteer me. </p>
            <a href = "mailto: silke1janssens@hotmail.com" className={styles.contactLink}><p className={styles.contactLinkText}>silke1janssens@hotmail.com</p></a> 
            <a href="/CurriculumVitae_JanssensSilke.pdf" download className={styles.contactLink}><p className={styles.contactLinkText}>Curriculum vitae</p></a>
          </div>
      </div>

      <div className={styles.imageGrid}>
        <div className={styles.weetjesgenerator} onClick={activateFall} id="weetjesgenerator">
          <Weetjesgenerator/>
        </div>
        {desktop &&
        <div className={styles.pictures}>
          <StaticImage className={styles.picture1} placeholder="none" src={"../../static/silke-hand.jpg"} alt="Picture of Silke in garden" />
          <StaticImage className={styles.picture2} placeholder="none" style={{opacity: `${opacity}`}} src="../../static/silke-triest.jpg" alt="Picture of Silke in garden" />
        </div>}
      </div>
    </div>
  </Layout>)
}

export const Head = () => <Seo title="Over mij" />

export default About;
