// extracted by mini-css-extract-plugin
export var about = "about-module--about--2JYMF";
export var aboutTekst = "about-module--aboutTekst--akzHx";
export var aboutTitle = "about-module--aboutTitle--7eMqw";
export var boldtext = "about-module--boldtext--DHZiH";
export var contact = "about-module--contact--hEU7m";
export var contactDescription = "about-module--contactDescription--PKaLI";
export var contactLink = "about-module--contactLink--wcxvv";
export var contactLinkText = "about-module--contactLinkText--q51H8";
export var grid = "about-module--grid--R8+Ks";
export var imageGrid = "about-module--imageGrid--gZaVY";
export var lineThrough = "about-module--lineThrough--PUi+p";
export var picture1 = "about-module--picture1--7sLuT";
export var picture2 = "about-module--picture2--YhUFn";
export var pictures = "about-module--pictures--6AfyY";
export var weetjesgenerator = "about-module--weetjesgenerator--Mxyg0";